<template>
  <m-sidebar-card
    :scope="scope"
    :on-save="save"
    :on-destroy="destroy"
    :saving="$wait.is('saving passage')"
    :destroying="$wait.is('destroying passage')"
    :is-new="isNew"
    :title="title"
  >
    <v-text-field
      ref="passageNameRef"
      v-model="passage.name"
      v-validate="{
        required: true
      }"
      :error-messages="errors.collect('name', scope)"
      data-vv-validate-on="blur"
      name="passage.name"
      :label="passageNamePlaceholder"
      outlined
    />

    <v-select
      v-if="isNew"
      :items="passages"
      :disabled="passages.length <= 0"
      :label="copyPassagePlaceholder"
      outlined
      clearable
      item-text="name"
      item-value="id"
      class="mt-4"
      :hint="copyPassageHint"
      persistent-hint
      @change="updateSelectedPassage"
    />

    <template v-slot:footer>
      <m-btn-help
        absolute
        :style="{
          right: '24px',
        }"
        class="mt-3"
        @click="() => startTour('MPassageEditor')"
      />
    </template>
  </m-sidebar-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import MSidebarCard from './MSidebarCard';
  import { tour } from '@/mixins/tour';

  export default {
    $_veeValidate: { validator: 'new' },

    components: {
      MSidebarCard,
    },

    mixins: [
      tour,
    ],

    data: () => ({
      passage: {},
      selectedPassageId: undefined,
      scope: 'passage',
    }),

    computed: {
      ...mapGetters({
        props: 'sidePanel/props',
        passageById: 'projectEditor/passages/passageById',
        passages: 'projectEditor/passages/passages',
      }),

      passageId() {
        return this.props.passageId;
      },

      onSave() {
        return this.props.onSave;
      },

      onBeforeDestroy() {
        return this.props.onBeforeDestroy;
      },

      isNew() {
        return this.passageId === null;
      },

      title() {
        if (this.isNew) {
          return this.$pgettext('MPassageEditor component title', 'New passage');
        }

        return this.$pgettext('MPassageEditor component title', 'Edit passage');
      },

      passageNamePlaceholder() {
        return this.$pgettext('MPassageEditor component text input placeholder', 'Passage name');
      },

      copyPassagePlaceholder() {
        return this.$pgettext('MPassageEditor component select input placeholder', 'Copy from another passage (optional)');
      },

      copyPassageHint() {
        return this.$pgettext('MPassageEditor component select input hint', 'Creates a new passage based on the selected passage');
      },
    },

    mounted() {
      this.initPassage();
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      ...mapWaitingActions('projectEditor/passages', {
        createPassage: 'saving passage',
        updatePassage: 'saving passage',
        destroyPassage: 'destroying passage',
      }),

      initPassage() {
        if (this.isNew) return;
        this.passage = cloneDeep(this.passageById(this.passageId));
      },

      async save() {
        const valid = await this.$validator.validateAll(this.scope);

        if (!valid) return;

        let passage;

        if (this.isNew) {
          passage = await this.create();
        } else {
          passage = await this.update();
        }

        this.onSave(passage);
        this.closeSidePanel();
      },

      create() {
        const params = {
          payload: {
            passage: {
              name: this.passage.name,
            },
          },
        };

        if (this.selectedPassageId) {
          params.payload.sourcePassageId = this.selectedPassageId;
        }

        return this.createPassage(params);
      },

      update() {
        const params = {
          id: this.passage.id,
          payload: {
            passage: {
              name: this.passage.name,
            },
          },
        };

        return this.updatePassage(params);
      },

      async destroy() {
        await this.onBeforeDestroy();

        await this.destroyPassage({
          id: this.passage.id,
        });

        this.closeSidePanel();
      },

      updateSelectedPassage(passageId) {
        this.selectedPassageId = passageId;
      },
    },
  };
</script>
